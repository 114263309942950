import React from 'react';
import { useTelegram } from '../hooks/useTelegram';
import { useLocation } from 'react-router-dom';
import IncomingTransfer from '../components/IncomingTransfer';
import OutgoingTransfer from '../components/OutgoingTransfer';
import Header from '../components/Header';


const Transfer = () => {
    const {tg,user} = useTelegram();
      
    const location = useLocation();
    const { id,status,amount,sender_id,receiver_id,method,isIncoming } = location.state;

    return (
        <div className=' h-[100vh]  bg-bg_color'>
            <Header id={id}/>
            {isIncoming
            ?<IncomingTransfer id={id} status={status} method={method} amount={amount} sender_id={sender_id}/>
            :<OutgoingTransfer id={id} status={status} receiver_id={receiver_id} amount={amount} method={method}/>}
        </div>
    );
};

export default Transfer;