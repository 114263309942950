import React from 'react';
import {useState,useEffect} from 'react';
import { partner } from '../../../interfaces/interfaces';
import {useNavigate} from 'react-router-dom';





const PendingTransfer = ({...props}) => {
    const navigate = useNavigate();
    const [methods,setMethods] = useState<any>({});
    const [receiver,setReceiver] = useState<partner | null>(null);
    

        useEffect(() => {
        async function getReceiver() {
                const response = await fetch(`${process.env.REACT_APP_API_HOST}/getPartnerById?id=${props.receiver_id}`);
                const data = await response.json();
                setReceiver(data);
        }
        if(props.receiver_id){
            getReceiver();
        }
        
    }, []);

    useEffect(()=>{
        if (receiver!==null) {
            const keys = Object.keys(receiver);
            const values = Object.values(receiver);
            const newMethods:any = {};
            for (let i = 5;i<9;++i){
                if(values[i]!=='0'){
                    newMethods[keys[i].substring(0,keys[i].length-8)]=values[i];
                }
            }
    setMethods(newMethods);
}},[receiver])

    const agreeTransfer = async (method:string)=>{
            const data = {transferId:props.id,method:method}
            await fetch(`${process.env.REACT_APP_API_HOST}/agreeTransfer`,
            {method:"POST",
            headers: {
                'Content-Type': 'application/json',
              },
            body: JSON.stringify(data), }
            ).then(response => {response.json();
                                props.setMethod(method)
                                props.setStatus('agreed');}) 
            .catch((error) => {
              console.error('Error:', error);
            });
    }

    const declineTransfer = async ()=>{
        const data = {transferId:props.id}
        await fetch(`${process.env.REACT_APP_API_HOST}/declineTransfer`,
        {method:"POST",
        headers: {
            'Content-Type': 'application/json',
          },
        body: JSON.stringify(data), }
        ).then(response => {response.json();
            navigate('/main');})
        .catch((error) => {
          console.error('Error:', error);
        });
}

    return (
        <div className='pending_transfer transfer-container'>
                <div className='question flex flex-wrap justify-center items-center h-[15vh]'>
                <p className='w-[85vw] text-2xl text-center mt-7  text-text_color'>Are you agree to send ${props.amount}? If so, choose available method below else decline transfer</p>
                </div>
                <div className='answers mt-7 flex flex-wrap justify-center content-start w-[80vw] h-[30vh]'>
                    {methods
                    ?Object.keys(methods).map((method,i)=>{
                        return <button onClick={()=>agreeTransfer(method)} key={i} className='bg-button_color w-[55vw] mt-3 h-[4vh] rounded-lg flex justify-center items-center '><p className='text-text_color text-2xl'>{method}</p></button>
                    })
                    
                    :<div></div>
                    }
                    <button onClick={()=>declineTransfer()} className='bg-[#E93131] w-[55vw] mt-5 h-[4vh] rounded-lg flex justify-center items-center  '><p className='text-text_color text-2xl'>decline</p></button>
                </div>
            </div>
    );
};

export default PendingTransfer;