import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { partner } from '../../../interfaces/interfaces';

const TransferAdmin = () => {

    const location = useLocation();
    const { id,status,amount,sender_id,receiver_id,method } = location.state;

    const [sender,setSender] = useState<partner|null>(null);
    const [receiver,setReceiver] = useState<partner|null>(null);

    
    useEffect(()=>{
        async function fetchReceiver() {
            const response = await fetch(`${process.env.REACT_APP_API_HOST}/getPartnerById?id=${receiver_id}`);
            const data = await response.json();
            setReceiver(data);
        }

        async function fetchSender() {
            const response = await fetch(`${process.env.REACT_APP_API_HOST}/getPartnerById?id=${sender_id}`);
            const data = await response.json();
            setSender(data);
        }

        fetchReceiver();
        fetchSender();

    },[])

    return (
        (sender && receiver)
        ?<div className='flex flex-wrap justify-center content-start h-[100vh] bg-bg_color font-Baloo text-text_color '>
        <div className='header w-[70%] text-4xl text-center mt-4'>transfer #{id}</div>
        <div className="amount adm-trf-sec"><p>amount: {amount} </p></div>
        <div className="status adm-trf-sec"><p>status: {status}</p></div>
        <div className="sender adm-trf-sec"><p>sender: {sender.nickname}</p></div>
        <div className="receiver adm-trf-sec"><p>receiver: {receiver.nickname}</p></div>
        <div className="method adm-trf-sec"><p>method: {method?method:'none'}</p></div>
    </div> 
        :<div></div>
    );
};

export default TransferAdmin;