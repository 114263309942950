import React from 'react';
import {useState,useEffect} from 'react';
import { transfer,partner } from '../../interfaces/interfaces';
import { useTelegram } from '../hooks/useTelegram';
import { useNavigate } from 'react-router-dom';
import profileLogo from '../img/profile_sign.png';
import infoLogo from '../img/info.png'
import PullToRefresh from 'react-simple-pull-to-refresh';
import {CSSTransition,TransitionGroup} from 'react-transition-group';
import "../App.css"



const Main = () => {
        const [incomingTransfers, setIncomingTransfers] = useState<transfer[] | []>([]);
        const [outgoingTransfers, setOutgoingTransfers] = useState<transfer[] | []>([]);
        const [partner,setPartner] = useState<partner>();
        const {user,tg} = useTelegram();
        const userId = user?.id;
        const navigate = useNavigate();
        const [isDark,setIsDark] = useState<boolean | null>(null);
        const [isModal,setIsModal] = useState<boolean>(false);



    function isDarkColor(hexColor:string) {
        // Remove the hash if it's there
        hexColor = hexColor?.replace('#', '');
    
        // Convert the hex color to RGB
        const r = parseInt(hexColor?.substr(0, 2), 16);
        const g = parseInt(hexColor?.substr(2, 2), 16);
        const b = parseInt(hexColor?.substr(4, 2), 16);
    
        // Calculate the brightness according to the formula: (Red value X 299 + Green value X 587 + Blue value X 114) / 1000
        const brightness = (r * 299 + g * 587 + b * 114) / 1000;
    
        // If the brightness is less than 128, it's a dark color, otherwise it's a light color
        return brightness < 128 ? true : false;
    }

    useEffect(()=>{
        if (tg){
            setIsDark(isDarkColor(tg.themeParams.bg_color))
        }
    },[tg])

        async function fetchIncoming() {
            const response = await fetch(`${process.env.REACT_APP_API_HOST}/getIncomingTransfers?userId=${userId}`);           
            const data = await response.json();
            setIncomingTransfers(data);
          }
        async function fetchOutgoing() {
            const response = await fetch(`${process.env.REACT_APP_API_HOST}/getOutgoingTransfers?userId=${userId}`);
            const data = await response.json();
            setOutgoingTransfers(data);
        }
        async function fetchPartner() {
            const response = await fetch(`${process.env.REACT_APP_API_HOST}/getPartnerByUserId?userId=${userId}`);
            const data = await response.json();
            setPartner(data);
        }

        const handleRefresh = async () =>{
            setIncomingTransfers([]);
            setOutgoingTransfers([])
            fetchIncoming();
            fetchOutgoing();
            fetchPartner();
        }

        useEffect(() => {
            fetchPartner();
            fetchIncoming();
            fetchOutgoing();
          }, []);
          const goToTransfer = (id:number,status:string,amount:number,sender_id:number,receiver_id:number,method:string,isIncoming:boolean) => {
            navigate('/transfer',{state:{id,status,amount,sender_id,receiver_id,method,isIncoming}});
        }

    return (
        <PullToRefresh onRefresh={handleRefresh} resistance={4}>
            
            <div className='flex justify-center content-start flex-wrap mt font-Baloo bg-bg_color h-[100vh] select-none' >
            {isDark
            ?<div className="header h-10 flex w-[100vw] pt-5 justify-center font-Baloo select-none">
            <img src={profileLogo} className='w-[40px] h-[40px] invert ml-72' onClick={()=>navigate('/settings')} alt="profile" />
            </div>
            :<div className="header h-10 flex w-[100vw] pt-5 justify-around font-Baloo select-none">
            <img src={profileLogo} className='w-[40px] h-[40px] ml-72' onClick={()=>navigate('/settings')} alt="profile" />
            </div>}
                {partner
                ?<div className='owing w-[55%] text-text_color text-2xl '>
                    {(partner.balance-partner.payments)<=0
                    ?<p className='text-center'>you're owed {Math.abs(partner.balance-partner.payments)}$</p>
                    :<div className='flex justify-center flex-wrap'><p>you owe {Math.abs(partner.balance-partner.payments)}$ </p>
                    {/* <div className="tooltip-container">
                    {isDark
                    ?<img className='ml-1 w-7 h-7 invert' src={infoLogo} alt='info' onClick={() => {setIsModal(!isModal);setTimeout(()=>setIsModal(false),2500)}}></img>
                    :<img className='ml-1 w-7 h-7' src={infoLogo} alt='info' onClick={() => {setIsModal(!isModal);setTimeout(()=>setIsModal(false),2500)}}></img>}
                    {isModal && (
                        <div className="tooltip-content">
                            <div className='text-lg break-normal text-text_color text-center'>This is an amount after all transfers will complete. Real amount is ${Math.abs(partner.net_balance)}$</div>
                        </div>
                    )}
                    </div> */}
                    <div className="bg-button_color rounded-lg w-[100%] h-9 mt-2 flex justify-around items-center text-text_color">
                    {(partner.balance-partner.payments)>0
                    ?<button onClick={()=>navigate('/create')} className=''> create transfer</button>
                    :<div></div>}
                    </div>
                    </div>}
                </div>
                :<div className='owing'></div>
                }
            <div className="incoming w-[80%] mt-8">
                <div className="transfers-block-header text-text_color ">Incoming transfers:</div>
                <TransitionGroup className='transfers-block'>
                    {incomingTransfers.map(tr =>{
                        return <CSSTransition key={tr.id}
                        timeout={700}
                        classNames='transfer-container'>
                            <div className='transfer mt-2'  onClick={()=> goToTransfer(tr.id,tr.status,tr.amount,tr.sender_id,tr.receiver_id,tr.method,true)}><p> transfer #{tr.id}</p><p> ${tr.amount}</p></div>
                        </CSSTransition>
                    })}
                </TransitionGroup>
            </div>
            <div className="Outgoing w-[80%] mt-6 ">
                <div className="transfers-block-header text-text_color">Outgoing transfers:</div>
                <TransitionGroup className='transfers-block'>
                {outgoingTransfers.map(tr =>{
                    return <CSSTransition 
                    key={tr.id}
                    timeout={700}
                    classNames="transfer-container">
                        <div className='transfer mt-2 ' onClick={()=> goToTransfer(tr.id,tr.status,tr.amount,tr.sender_id,tr.receiver_id,tr.method,false)}> <p>transfer #{tr.id}</p><p> ${tr.amount}</p></div>
                    </CSSTransition>
                })}
                </TransitionGroup>
            </div>
            
        </div>
        </PullToRefresh>
    );
};

export default Main;