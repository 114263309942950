import React, { useEffect, useState } from 'react';
import {AutoComplete} from 'antd';
import homeLogo from '../../img/home.png'
import { useNavigate } from 'react-router-dom';

const AddTransfer = () => {

    const navigate = useNavigate();

const [receiverInput,setReceiverInput] = useState("");
const [senderInput,setSenderInput] = useState("");
const [receiverId,setReceiverId] = useState("");
const [senderId,setSenderId] = useState("");
const [amountInput,setAmountInput] = useState("");
const [partners,setPartners] = useState([]);
const [users,setUsers] = useState([{}]);
const [isSent,setIsSent] = useState(false);
const [method,setMethod] = useState('');


useEffect(()=>{
    async function fetchTransfers(){
        const response = await fetch(`${process.env.REACT_APP_API_HOST}/getAllPartners`);
        const data = await response.json()
        setPartners(data)
    }
    fetchTransfers()
},[])

useEffect(()=>{
    let temp = []
    for (let i = 0; i<partners.length;i++){
        temp.push({label:partners[i]['nickname'],value:partners[i]['nickname']});
    }
    setUsers(temp)
},[partners])


useEffect(()=>{
    const fetchIds = async () =>{
        const res1 = await fetch(`${process.env.REACT_APP_API_HOST}/getPartnerByNickname?nick=${receiverInput}`);
        const data1 = await res1.json();
        setReceiverId(data1?.id)
        const res2 = await fetch(`${process.env.REACT_APP_API_HOST}/getPartnerByNickname?nick=${senderInput}`);
        const data2 = await res2.json();
        setSenderId(data2?.id)
    }
    fetchIds();
}
,[receiverInput,senderInput])

const createTransfer = () =>{
    
    if (receiverId && senderId && amountInput && method){
        let data = {
            receiver_id:Number(receiverId),
            sender_id:Number(senderId),
            amount:Number(amountInput),
            method:method,
            status:'pending'
        }
    
        fetch(`${process.env.REACT_APP_API_HOST}/addTransfer`,{
            method:"POST",
            headers: {
                'Content-Type': 'application/json',
              },
            body: JSON.stringify(data), 
        })
    
        
            setIsSent(true);
        setTimeout(() => {
            navigate(0);
        }, 1500);
    }
    
}


    return (
        <div className='font-Baloo flex justify-center flex-wrap h-[100vh] content-start bg-bg_color'>
            <div className="header mt-4 flex items-center h-[8vh] text-text_color"><p className='text-4xl'>create transfer</p><img onClick={() => navigate('/panel')} className='h-[4vh] pl-4' src={homeLogo} alt='home' /></div>
            <div className="inputs w-[85%] flex justify-center flex-wrap">
                <div className="name mt-3">
                    <div className="input-header">sender's @</div>
                    {/* <input className='data-input' value={senderInput} onChange={(e)=>setSenderInput(e.target.value)}></input> */}
                    <AutoComplete filterOption onSelect={(value,option) => {setSenderInput(value)}} options={users} className='bg-secondary_bg_color border-text_color text-text_color outline-none rounded-md w-60 h-7 text-base'/>
                </div>
                <div className="name mt-3">
                    <div className="input-header">receiver's @</div>
                    {/* <input className='data-input' value={receiverInput} onChange={(e)=>setReceiverInput(e.target.value)}></input> */}
                    <AutoComplete filterOption onSelect={(value,option) => {if (senderInput!==value){setReceiverInput(value)}}} options={users} className='bg-secondary_bg_color border-text_color text-text_color outline-none rounded-md w-60 h-7 text-base'/>
                </div>
                <div className="name mt-3">
                    <div className="input-header">amount</div>
                    <input className='data-input h-[32px]' value={amountInput} onChange={(e)=>setAmountInput(e.target.value)}></input>
                </div>
                <div className="name mt-3">
                    <div className="input-header">method</div>
                    <select onChange={(e)=>setMethod(e.target.value)} className='data-input h-[32px] bg-secondary_bg_color border-text_color text-text_color outline-none rounded-md w-60 h-7 text-base'>
                        <option value="paypal">paypal</option>
                        <option value="btc">btc</option>
                        <option value="eth">eth</option>
                        <option value="cash app">cash app</option>
                        <option value="venmo">venmo</option>
                    </select>
                </div>
            </div>
            {isSent
            ?<div className='bg-[#00DB09] w-[40vw] h-[4vh] mt-3 rounded-lg text-text_color flex justify-center content-center items-center'><p>transfer created!</p></div>
            :<button className='bg-button_color w-[40vw] h-[4vh] mt-3 rounded-lg text-text_color flex justify-center content-center items-center' onClick={createTransfer}><p>create</p></button>}
            
        </div>
    );
};

export default AddTransfer;