import React, { useEffect, useState } from 'react';
import homeLogo from '../img/home.png';
import profileLogo from '../img/profile_sign.png';
import { useNavigate } from 'react-router-dom';
import { useTelegram } from '../hooks/useTelegram';


const Header = ({...props}) => {
    const navigate = useNavigate();
    const {tg} = useTelegram();
    const [isDark,setIsDark] = useState<boolean | null>(null);



    function isDarkColor(hexColor:string) {
        // Remove the hash if it's there
        hexColor = hexColor?.replace('#', '');
    
        const r = parseInt(hexColor?.substr(0, 2), 16);
        const g = parseInt(hexColor?.substr(2, 2), 16);
        const b = parseInt(hexColor?.substr(4, 2), 16);
    
        const brightness = (r * 299 + g * 587 + b * 114) / 1000;
    
        return brightness < 128 ? true : false;
    }

    useEffect(()=>{
        if (tg){
            setIsDark(isDarkColor(tg.themeParams.bg_color))
            console.log(tg)
        }
    },[tg])
     

    return (
        isDark
        ?<div className="header h-10 flex w-[100vw] pt-5 justify-around font-Baloo">
        <img src={homeLogo} className='w-[40px] h-[40px] invert' onClick={()=>navigate('/main')} alt="mainmenu" />
        <div className='text-3xl text-text_color  flex justify-center items-center'><p className=' pt-6 text-text_color'>transfer #{props.id}</p></div>
        <img src={profileLogo} className='w-[40px] h-[40px] invert' onClick={()=>navigate('/settings')} alt="profile" />
        </div>
        :<div className="header h-10 flex w-[100vw] pt-5 justify-around font-Baloo">
        <img src={homeLogo} className='w-[40px] h-[40px]' onClick={()=>navigate('/main')} alt="mainmenu" />
        <div className='text-3xl text-text_color  flex justify-center items-center'><p className=' pt-6 text-text_color'>transfer #{props.id}</p></div>
        <img src={profileLogo} className='w-[40px] h-[40px]' onClick={()=>navigate('/settings')} alt="profile" />
        </div>
    );
};

export default Header;