import React from 'react';
import { useTelegram } from '../hooks/useTelegram';
import {useEffect} from 'react';
import {useNavigate} from 'react-router-dom'

const Room = () => {
    const {user} = useTelegram();
    const userId = user?.id;
    const navigate = useNavigate();

    useEffect(()=>{
        const isExist = async ()=> {
          const response = await fetch(`${process.env.REACT_APP_API_HOST}/getPartnerByUserId?userId=${userId}`, {
            method: 'GET',
            credentials: 'include', // Important: include credentials because your CORS setup on the server expects it
            headers: {
              'Content-Type': 'application/json',
              // Other headers here as needed
            },
          });
          const data = await response.json();
          
            console.log(data)
            if (data){
                if (data.admin){
                    navigate('/panel')
                }else{
                    if (data.ready==null){
                        navigate('/settings')
                    }else{
                        navigate('/main')
                    }
                }
            }else{
                navigate('/reg')
            }
        } 
        isExist();
    },[])

    return (
        <div  className='flex justify-center h-[100vh] items-center font-Baloo bg-bg_color'>
            <p className='text-3xl text-text_color select-none'>loading...</p>
        </div>
    );
};

export default Room;