import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Main from './pages/Main';
import Register from './pages/Register';
import Room from './pages/Room';
import Transfer from './pages/Transfer';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter,RouterProvider } from 'react-router-dom';
import Settings from './pages/Settings';
import Panel from './pages/admin/Panel';
import TransferAdmin from './pages/admin/TransferAdmin';
import Archive from './pages/admin/Archive';
import AddTransfer from './pages/admin/AddTransfer';
import TransferCreating from './pages/TransferCreating';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const router = createBrowserRouter([
  {
    path: "/",
    element: <Room/>
  },
  {
    path: "/main",
    element: <Main/>,
  },
  {
    path:"/reg",
    element: <Register/>
  },
  {
    path:"/transfer",
    element:<Transfer/>,
  },
  {
    path:"/settings",
    element:<Settings/>,
  },
  {
    path:"/panel",
    element:<Panel/>
  },
  {
    path:"/transfer_admin",
    element:<TransferAdmin/>
  },
  {
    path:"/archive",
    element:<Archive/>
  },
  {
    path:"/addTransfer",
    element:<AddTransfer/>
  },
  {
    path:"/create",
    element:<TransferCreating/>
  }

]);


root.render(
  <React.StrictMode>
    <RouterProvider router={router}/>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
