import React, { MouseEvent, useState, useRef, useEffect } from 'react';

interface ButtonProps {
  onSubmit: () => void;
  reclickToConfirm?: boolean;
  width?: string | number;
  height?: string | number;
  color?: string;
  marginTop?: string;
  text: string;
  textSize?: string; // new prop for text size
}

const Button: React.FC<ButtonProps> = ({ onSubmit, reclickToConfirm = false, width, height, color, marginTop= '', text, textSize = 'text-xl' }) => {
  const [clickCount, setClickCount] = useState(0);
  const [buttonText, setButtonText] = useState(text);
  const clickTimeoutRef = useRef<number | null>(null);

  useEffect(() => {
    setButtonText(clickCount > 0 ? 'Re-Click' : text);
  }, [clickCount, text]);

  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    
    if (!reclickToConfirm) {
      onSubmit();
      return;
    }
    
    setClickCount(prevCount => prevCount + 1);

    if (clickTimeoutRef.current !== null) {
      clearTimeout(clickTimeoutRef.current);
    }

    clickTimeoutRef.current = window.setTimeout(() => {
      setClickCount(0);
    }, 2100); // Timeout updated
    
    if (clickCount >= 1) {
      onSubmit();
      setClickCount(0);
      if (clickTimeoutRef.current !== null) {
        clearTimeout(clickTimeoutRef.current);
      }
    }
  };

  return (
    <button
      onClick={handleClick}
      className={`flex justify-center items-center rounded-md text-text_color bg-button_color`}
      style={{ width, height, backgroundColor: color, marginTop }}
    >
      <p className={`${textSize}`}>{buttonText}</p> {/* Using textSize prop */}
    </button>
  );
};

export default Button;
