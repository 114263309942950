import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../Button';


const SentTransfer = ({...props}) => {

    const navigate = useNavigate();

    return (
        <div className='sent_tranfer transfer-container' >
            <p className='w-[85vw] text-2xl text-center mt-3 text-text_color'  text-text_color> transfer is sent and waiting for receiver’s confirmation  </p>
            <Button textSize='text-2xl' marginTop='16px' width='25vw' color='#E93131' height='5vh' onSubmit={()=>navigate('/main')} text='menu' />
        </div>
    );
};

export default SentTransfer;