import React from 'react';
import {useState,useEffect} from 'react';
import PendingTransfer from './Outgoings/PendingTransfer';
import AgreedTransfer from './Outgoings/AgreedTransfer';
import SentTransfer from './Outgoings/SentTransfer';

const OutgoingTransfer = ({...props}) => {
    const [status,setStatus] = useState(props.status);
    const [method,setMethod] = useState(null);

    




    if (status==='pending'){
        return (
            <PendingTransfer setMethod={setMethod} receiver_id={props.receiver_id} sender_id={props.sender_id} amount={props.amount} setStatus={setStatus} id={props.id}/>
        );
    }else if(status==='agreed'){
        return (
            <AgreedTransfer id={props.id} amount={props.amount} method={method || props.method} setStatus={setStatus} receiver_id={props.receiver_id} sender_id={props.sender_id}/>
        );
    }else if(status==='sent'){
        return (
            <SentTransfer id={props.id} />
        );
    }else return <div></div>

    
};

export default OutgoingTransfer;