import React from 'react';
import {useState} from 'react';
import { useTelegram } from '../hooks/useTelegram';
import { useNavigate } from 'react-router-dom';
import Button from '../components/Button'

const Register = () => {
    const {tg,user} = useTelegram();
    const nick = user.username;
    const navigate = useNavigate();

    const signUp = async ()=>{
        let data = {
            name: name,
            user_id: user.id,
            nickname: nick,
            paypal_address: paypal ,
            btc_address: btc,
            eth_address: eth,
            cash_app_address: app, 
            venmo_address: venmo,
          };
          
          fetch(`${process.env.REACT_APP_API_HOST}/addPartner`, {
            method: 'POST', 
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(data), 
          })
          .then(response => {response.json();
                            navigate('/main');
        }) 
          .catch((error) => {
            console.error('Error:', error);
          });

    }

    const [name,setName] = useState((user?.first_name+user?.last_name)|| '');
    const [paypal,setPaypal] = useState('');
    const [btc,setBtc] = useState('');
    const [eth,setEth] = useState('');
    const [app,setApp] = useState('');
    const [venmo,setVenmo] = useState('');
    

    return (

        <div className='flex justify-center font-Baloo flex-wrap  bg-bg_color h-[100vh] content-start'>
            <div className="header w-[85%] text-center text-3xl mt-4 text-text_color">you haven’t registered fill the next data</div>
            <div className="inputs w-[85%] flex justify-center flex-wrap">
                <div className="name mt-3">
                    <div className="input-header">name</div>
                    <input className='data-input' value={name} onChange={(e)=>setName(e.target.value)}></input>
                </div>
                <div className="name mt-3">
                    <div className="input-header">paypal</div>
                    <input className='data-input' value={paypal} onChange={(e)=>setPaypal(e.target.value)}></input>
                </div>
                <div className="name mt-3">
                    <div className="input-header">btc wallet</div>
                    <input className='data-input' value={btc} onChange={(e)=>setBtc(e.target.value)}></input>
                </div>
                <div className="name mt-3">
                    <div className="input-header ">eth wallet</div>
                    <input className='data-input' value={eth} onChange={(e)=>setEth(e.target.value)}></input>
                </div>
                <div className="name mt-3">
                    <div className="input-header">cash app</div>
                    <input className='data-input' value={app} onChange={(e)=>setApp(e.target.value)}></input>
                </div>
                <div className="name mt-3">
                    <div className="input-header">venmo</div>
                    <input className='data-input' value={venmo} onChange={(e)=>setVenmo(e.target.value)}></input>
                </div>
            </div>
            <Button height='4vh' width='24vw' marginTop='20px' color='bg-button_color' text='Sign up' onSubmit={() => signUp()}/>
        </div>
    );
};

export default Register;