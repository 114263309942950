import React from 'react';
import {useState,useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../Button';

const AgreedTransfer = ({...props}) => {
    const [receiver,setReceiver] = useState(null);
    const navigate = useNavigate();

    const sendTransfer = async ()=>{
        const data = {transferId:props.id,new_status:'sent'}
            await fetch(`${process.env.REACT_APP_API_HOST}/sendTransfer`,
            {method:"POST",
            headers: {
                'Content-Type': 'application/json',
              },
            body: JSON.stringify(data), }
            ).then(response => {response.json()
                props.setStatus('sent')})
            .catch((error) => {
              console.error('Error:', error);
            });
    }

    const cancelTrasfer = async ()=>{

        navigate('/main');

        const data = {transferId:props.id}
            await fetch(`${process.env.REACT_APP_API_HOST}/cancelTransfer`,
            {method:"POST",
            headers: {
                'Content-Type': 'application/json',
              },
            body: JSON.stringify(data), }
            ).then(response => {response.json()
                props.setStatus('sent')})
            .catch((error) => {
              console.error('Error:', error);
            });
    }

    useEffect(() => {
        async function getReceiver() {
                const response = await fetch(`${process.env.REACT_APP_API_HOST}/getPartnerById?id=${props.receiver_id}`);
                const data = await response.json();
                setReceiver(data);
        }
        if(props.receiver_id){
            getReceiver();
        }
    },[])

    return (
        <div className='agreed_transfer transfer-container'>
            <div className='question flex flex-wrap justify-center items-center h-[22vh]'>
                {(receiver!==null)
                ?<p className='w-[85vw] text-2xl text-center mt-7 text-text_color'>Please send ${props.amount} to {receiver[props.method+'_address']} via {props.method} and then press button below</p>
                :<p></p>
                }
            </div>
            
            <Button textSize='text-2xl' width='55vw' height='5vh' marginTop='25px' onSubmit={()=>sendTransfer()} text='sent' reclickToConfirm={true}/>
            <Button textSize='text-2xl' marginTop='8px' width='55vw' color='#E93131' height='5vh' onSubmit={()=>cancelTrasfer()} text='cancel transfer' reclickToConfirm={true}/>
        </div>
    );
};

export default AgreedTransfer;