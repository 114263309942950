import React,{useEffect,useState} from 'react';
import { useTelegram } from '../hooks/useTelegram';
import { useNavigate } from 'react-router-dom';
import Button from '../components/Button';
import homeLogo from '../img/home.png'



const TransferCreating = () => {
    const [partners,setPartners] = useState<any>([{name:'choose transfer receiver ',balance:'...',id:0}]);
    const [sender,setSender] = useState<any>()
    const [receiverId,setReceiverId] = useState<any>();
    const [maxAmount,setMaxAmount] = useState<any>();
    const [amount,setAmount] = useState<any>('');
    const [methods,setMethods] = useState<any>([{name:'choose method',field:'1'}]);
    const [transferMethod,setTransferMethod] = useState<any>();
    const [error, setError] = useState<string | null>(null);
    const [isDark,setIsDark] = useState<boolean | null>(null);
    const navigate = useNavigate();

    const {user,tg} = useTelegram();




    function isDarkColor(hexColor:string) {
        hexColor = hexColor?.replace('#', '');
    
        const r = parseInt(hexColor?.substr(0, 2), 16);
        const g = parseInt(hexColor?.substr(2, 2), 16);
        const b = parseInt(hexColor?.substr(4, 2), 16);
    
        const brightness = (r * 299 + g * 587 + b * 114) / 1000;
    
        return brightness < 128 ? true : false;
    }

    useEffect(()=>{
        if (tg){
            setIsDark(isDarkColor(tg.themeParams.bg_color))
            console.log(tg)
        }
    },[tg])
     
 
    useEffect(()=>{
        async function fetchPartners(){
            const response = await fetch(`${process.env.REACT_APP_API_HOST}/getAllPartners`);
            const data = await response.json()
            let pos = data.filter((p:any)=>{if((p.balance-p.payments)<0 && p.admin===false && p.ready===true) return p});
            if (pos?.length>0){
                setPartners([{name:'choose transfer receiver',balance:0,id:0},...pos]);
            }else{
                setPartners([{name:'choose transfer receiver',balance:0,id:0},...data.filter((p:any)=>{if(p.admin === true) return p})]);
            }
        }
        async function getSenderId(){
            const response = await fetch(`${process.env.REACT_APP_API_HOST}/getPartnerByUserId?userId=${user?.id}`);
            const data = await response.json()
            
            setSender(data);
        }
        getSenderId()
        fetchPartners()
    },[])

    async function createTransfer() {
        if (!receiverId || !amount || !transferMethod || transferMethod === 'choose method') {
            setError("Please ensure all fields are selected and filled in correctly.");
            return;
        }
   
        let data = {
            receiver_id: receiverId,
            sender_id: sender.id,
            amount: amount,
            method:transferMethod,
            status:'agreed',
        };

        navigate('/main')
   
        await fetch(`${process.env.REACT_APP_API_HOST}/addTransfer`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        

        .then((response) => {response.json()})
        .then(() => {
            setError(null);
        })
        .catch((error) => {
            console.error('Error:', error);
            setError("There was an error processing the transfer.");
        });


   }

    const handleInputChange = (e:any) => {
        const value = e.target.value;
        
        if (value === "" || !isNaN(value) && (parseFloat(value) <= maxAmount && parseFloat(value)<=Math.abs(sender?.balance-sender?.payments))) {
            setAmount(value);
        }
    };

    useEffect(()=>{

    if (receiverId>0){
        let tmp = partners.filter((p:any) => {if (p.id == receiverId)return p});
        setMaxAmount(Math.abs(tmp[0]?.balance-tmp[0]?.payments));
        
    const methods = [
        { name: 'paypal', field: 'paypal_address' },
        { name: 'btc', field: 'btc_address' },
        { name: 'eth', field: 'eth_address' },
        { name: 'cash app', field: 'cash_app_address' },
        { name: 'venmo', field: 'venmo_address'}
    ];

    const availableMethods = methods.filter(method => {
        if (partners.filter((p:any) => {if (p.id == receiverId)return p})[0][method.field]){return method}
    })

    setMethods([{name:'choose method',field:'1'},...availableMethods])
    }

    },[receiverId])

    return (
        <div className='flex flex-wrap content-start justify-center bg-bg_color w-[100vw] h-[100vh] font-Baloo select-none'>
            <div className="header w-[75%] text-center text-3xl mt-5 text-text_color flex justify-start">
                {isDark
                ?<img onClick={()=>navigate('/main')} className='h-[7vh] pr-2 invert' src={homeLogo} alt="home" />
                :<img onClick={()=>navigate('/main')} className='h-[7vh] pr-2' src={homeLogo} alt="home" />}
                <p className='ml-21'>new transfer</p></div>
            <div className="inputs w-[85%] flex justify-center content-start flex-wrap">
                <div className="name mt-3">
                    <div className="input-header">receiver</div>
                    <select className='data-input' onChange={(e) => {setReceiverId(e.target.value)}}>
                        {partners.map((p:any)=>{
                            if(p.id===0){
                                return <option value={p.id} key={p.id}>{p.name}</option>
                            }else{
                            return <option value={p.id} key={p.id}>{p.name} up to {Math.abs(p.balance-p.payments)}$</option>}
                        })}
                     </select>
                </div>
                <div className="amount mt-3">
                    <div className="input-header">amount (you owe {Math.abs(sender?.balance-sender?.payments)}$)</div>
                    <input className='data-input' value={amount} onChange={handleInputChange}></input>
                </div>
                <div className="method mt-3">
                    <div className="input-header">method</div>
                    <select className='data-input' onChange={(e) => {setTransferMethod(e.target.value)}} >
                        {methods.map((m:any)=>{
                            return <option value={m.name} key={m.name}>{m.name}</option>
                        })}
                     </select>
                </div>
            </div>
            {error && <div className="error-message text-red-500 text-center pt-2">{error}</div>}
            <Button text='send' height='37px' width='85px' textSize='text-2xl' marginTop='20px' onSubmit={()=>createTransfer() }/>
        </div>
    );
};

export default TransferCreating;