import React from 'react';
import {useEffect,useState} from 'react';
import {useNavigate} from 'react-router-dom';
import Button from './Button';


const IncomingTransfer = ({...props}) => {

    const [sender, setSender] = useState<any>(null);

    const navigate = useNavigate();

    useEffect(() => {
        async function getSender() {
                    const response = await fetch(`${process.env.REACT_APP_API_HOST}/getPartnerById?id=${props.sender_id}`);
                    const data = await response.json();
                    setSender(data);
        }
        getSender();
    }, []);

    const approveTransfer =async  ()=>{

        let data = {
            transferId:props.id,
            receiver_id:props.receiver_id,
            sender_id:props.sender_id,
            amount:props.amount
        }

        fetch(`${process.env.REACT_APP_API_HOST}/approveTransfer`, {
            method: 'POST', 
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(data), 
          })
          .then(response => response.json()) 
          .catch((error) => {
            console.error('Error:', error);
          });

          navigate('/main')
    }
    
    return (
        <div className='incoming_transfer transfer-container'>

                {(sender!==null)
                ?<div className='question flex flex-wrap justify-center items-center h-[15vh]'>
                <p className='w-[85vw] text-2xl text-center mt-7 text-text_color'>Did you receive ${props.amount} at your {props.method} from {sender[props.method+'_address']}?</p>
                <div className='answers mt-2 flex flex-wrap justify-center items-center content-evenly w-[30vw] h-[15vh]'>
                    <Button width='26vw' height='5vh' color='#00DB09' reclickToConfirm={true} onSubmit={()=>approveTransfer()} text='yes'/>
                    <Button width='26vw' height='5vh' color='#E93131' onSubmit={()=>navigate('/main')} text='not yet'/>
                </div>
                </div>
                
                :<div></div>
                }
                

            </div>
    );
};

export default IncomingTransfer;