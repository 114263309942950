import React, { useEffect, useState } from 'react';
import { transfer } from '../../../interfaces/interfaces';
import '../../App.css'
import { useNavigate } from 'react-router-dom';
import archiveLogo from '../../img/archive_logo.png'
import { useTelegram } from '../../hooks/useTelegram';

type TransferKey = "sender's name" | "receiver's name" | 'amount' | 'sender @' | 'receiver @'

const Panel = () => {
    const [searchInput, setSearchInput] = useState('');
    const [dropDown, setDropDown] = useState<TransferKey>('sender @');
    const [transfers,setTransfers] = useState<transfer[]>([]);
    const [filteredTransfers,setFilteredTransfers] = useState<transfer[]>([])
    const {user} = useTelegram();
    const navigate = useNavigate();
    const [partner,setPartner] = useState<any>();

    useEffect(()=>{
        
        async function fetchPartner(){
            const response = await fetch(`${process.env.REACT_APP_API_HOST}/getPartnerByUserId?userId=${user?.id}`);
            const data = await response.json()
            setPartner(data)
        }
        
        async function fetchTransfers(){
            const response = await fetch(`${process.env.REACT_APP_API_HOST}/getAllTransfers`);
            const data = await response.json()
            setTransfers(data)
            setFilteredTransfers(data)
        }
        fetchTransfers();
        fetchPartner();
    },[])

    const goToTransfer = (id:number,status:string,amount:number,sender_id:number,receiver_id:number,method:string) => {
        navigate('/transfer_admin',{state:{id,status,amount,sender_id,receiver_id,method}});
    }

    useEffect(()=>{
        setFilteredTransfers(transfers.filter(tr => {
            if (searchInput===''){
                return transfers
            }else{
                switch (dropDown) {
                    case 'amount':
                        return tr[dropDown] === Number(searchInput);
                    case 'sender @':
                        return tr['sender_nickname'] == searchInput.toLowerCase()
                    case 'receiver @':
                        return tr['receiver_nickname']
                    case "sender's name":
                        return tr['sender_name']
                    case "receiver's name":
                        return tr['receiver_name']
                }
            }
        }))
    },[searchInput,dropDown])

    if (partner){
        if (partner.admin){
            return(<div className='flex justify-center font-Baloo flex-wrap h-[100vh] content-start bg-bg_color text-text_color'>
                <div className="header mt-4 flex items-center h-[8vh] text-text_color"><p className='text-4xl'>current transfers</p><img onClick={() => navigate('/archive')} className='h-[4vh] pl-4' src={archiveLogo} alt='archive' /></div>
                <div className="flex justify-center content-center flex-wrap">
                        <div className="input-header">search by 
                            <select className='w-[59%] ml-2 bg-bg_color text-text_color' onChange={(e)=>setDropDown(e.target.value as TransferKey)}>
                            <option value="sender_name">sender @</option>
                            <option value="receiver_name">receiver @</option>
                            <option value="amount">amount</option>
                            </select>
                        </div>
                        <input name='search' className='data-input mt-2' value={searchInput} onChange={(e)=>setSearchInput(e.target.value)}></input>
                        <div className='transfers-block mt-3 w-[75vw]'>
                        <div className="transfer-container w-[100%]">
                            <div onClick={() =>navigate('/addTransfer')} className='transfer' ><p>create transfer</p></div>
                            {filteredTransfers.map(tr =>{
                                return <div key={tr.id} className='transfer mt-2' onClick={() => goToTransfer(tr.id,tr.status,tr.amount,tr.sender_id,tr.receiver_id,tr.method)}> <p>transfer #{tr.id}</p><p> ${tr.amount}</p></div>
                            })}
                        </div>
                    </div>
                </div>
            </div>)
        }else{
            return(<div></div>)
        }
    }else{
        return(<div></div>)
    }
};

export default Panel;
