import React, { useEffect, useState } from 'react';
import { transfer } from '../../../interfaces/interfaces';
import '../../App.css'
import { useNavigate } from 'react-router-dom';
import homeLogo from '../../img/home.png'

const Archive = () => {

    const [searchInput, setSearchInput] = useState('');
    const [dropDown, setDropDown] = useState('asd');
    const [transfers,setTransfers] = useState<transfer[]>([]);
    const navigate = useNavigate();

    useEffect(()=>{
        async function fetchTransfers(){
            const response = await fetch(`${process.env.REACT_APP_API_HOST}/getArchiveTransfers`);
            const data = await response.json()
            setTransfers(data)
        }
        fetchTransfers()
    },[])

    const goToTransfer = (id:number,status:string,amount:number,sender_id:number,receiver_id:number,method:string) => {
        navigate('/transfer_admin',{state:{id,status,amount,sender_id,receiver_id,method}});
    }

    return (
        <div className='flex justify-center font-Baloo flex-wrap h-[100vh] content-start bg-bg_color'>
            <div className="header mt-4 flex items-center h-[8vh] text-text_color"><p className='text-4xl'>archive transfers</p><img onClick={() => navigate('/panel')} className='h-[4vh] pl-4' src={homeLogo} alt='home' /></div>
            <div className="flex justify-center content-center flex-wrap">
                    <div className="input-header">search by 
                        <select className='w-[59%] ml-2 bg-bg_color text-text_color' onChange={(e)=>setDropDown(e.target.value)}>
                        <option value="sender">sender @</option>
                        <option value="receiver">receiver @</option>
                        <option value="amount">amount</option>
                        </select>
                    </div>
                    <input name='search' className='data-input mt-2' value={searchInput} onChange={(e)=>setSearchInput(e.target.value)}></input>
                    <div className='transfers-block mt-3 w-[75vw]'>
                    <div className="transfer-container w-[100%]">
                        <div onClick={() =>navigate('/addTransfer')} className='transfer' ><p>create transfer</p></div>
                        {transfers.map(tr =>{
                            return <div key={tr.id} className='transfer mt-2' onClick={() => goToTransfer(tr.id,tr.status,tr.amount,tr.sender_id,tr.receiver_id,tr.method)}> <p>transfer #{tr.id}</p><p> ${tr.amount}</p></div>
                        })}
                    </div>
                </div>
            </div>
            
        </div>
    );
};

export default Archive;