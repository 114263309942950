import React from 'react';
import {useState,useEffect} from 'react';
import { useTelegram } from '../hooks/useTelegram';
import { partner } from '../../interfaces/interfaces';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import Button from '../components/Button';

const Settings = () => {
    const [partner,setPartner] = useState<partner | null>(null);
    const [name,setName] = useState('');
    const [paypal,setPaypal] = useState('');
    const [btc,setBtc] = useState('');
    const [eth,setEth] = useState('');
    const [app,setApp] = useState('');
    const [venmo,setVenmo] = useState('');
    const [ready,setReady] = useState<boolean>();

    const navigate = useNavigate();
    const {user} = useTelegram();

    const userId = user?.id;
    useEffect(()=>{
        async function getPartner() {
            const response = await fetch(`${process.env.REACT_APP_API_HOST}/getPartnerByUserId?userId=${userId}`);
            const data = await response.json();
            setPartner(data)
        }
    getPartner();

    },[])

    useEffect(()=>{
        if(partner){
            setName(partner.name);
            if (partner.paypal_address?.length>1){
                setPaypal(partner.paypal_address)
            }   
            if (partner.btc_address?.length>1){
                setBtc(partner.btc_address)
            }   
            if (partner.eth_address?.length>1){
                setEth(partner.eth_address)
            }   
            if (partner.cash_app_address?.length>1){
                setApp(partner.cash_app_address)
            }
            if (partner.venmo_address?.length>1){
                setVenmo(partner.venmo_address)
            }
            setReady(partner.ready)
        }
    },[partner])

    const saveData = async ()=>{
        let data = {
            name: name,
            userId: userId,
            paypal_address: paypal ,
            btc_address: btc,
            eth_address: eth,
            cash_app_address: app,
            venmo_address: venmo,
            ready:ready
          };
          
          fetch(`${process.env.REACT_APP_API_HOST}/setParams`, {
            method: 'POST', 
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(data), 
          })
          .then(response => response.json()) 
          .catch((error) => {
            console.error('Error:', error);
          });
        navigate('/');
    }

    return (

        <div className='flex justify-center font-Baloo flex-wrap  bg-bg_color h-[100vh] content-start select-none'>
            <div className="header w-[85%] text-center text-3xl mt-5 text-text_color">settings</div>
            <div className="inputs w-[85%] flex justify-center flex-wrap">
                <div className="name mt-3">
                    <div className="input-header">name</div>
                    <input className='data-input' value={name} onChange={(e)=>setName(e.target.value)}></input>
                </div>
                <div className="name mt-3">
                    <div className="input-header">paypal</div>
                    <input className='data-input' value={paypal} onChange={(e)=>setPaypal(e.target.value)}></input>
                </div>
                <div className="name mt-3">
                    <div className="input-header ">btc wallet</div>
                    <input className='data-input' value={btc} onChange={(e)=>setBtc(e.target.value)}></input>
                </div>
                <div className="name mt-3">
                    <div className="input-header ">eth wallet</div>
                    <input className='data-input' value={eth} onChange={(e)=>setEth(e.target.value)}></input>
                </div>
                <div className="name mt-3">
                    <div className="input-header">cash app</div>
                    <input className='data-input' value={app} onChange={(e)=>setApp(e.target.value)}></input>
                </div>
                <div className="name mt-3">
                    <div className="input-header">venmo</div>
                    <input className='data-input' value={venmo} onChange={(e)=>setVenmo(e.target.value)}></input>
                </div>
                <div className="name mt-3">
                    <div className="input-header">are you ready to receive transfers?</div>
                    <button
                        className={classNames(
                            'outline-none rounded-md pl-2 w-60 h-7 text-base',
                            {
                            'text-green-500 bg-green-300 flex justify-center items-center ': ready,
                            'text-red-500 bg-red-300 flex justify-center items-center ': !ready,
                            }
                        )}
                        onClick={() => setReady(!ready)}
                        >
                        ready
                    </button>
            </div>
            <div className="buttons_block flex h-[35px] mt-5 w-[55vw] flex-wrap justify-around ">
            <Button height='4vh' width='20vw' text='menu' onSubmit={() => navigate('/')}/>
            <Button height='4vh' width='20vw' color='#00DB09' text='save' onSubmit={() => saveData()}/>

            </div>
        </div>
    </div>
)};

export default Settings;